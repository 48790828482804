<script lang="ts">
    import { createEventDispatcher } from 'svelte';
    import * as Dialog from '$lib/components/ui/dialog';
    import { Button } from '$lib/components/ui/button';
    import { Checkbox } from '$lib/components/ui/checkbox';
    import { Label } from '$lib/components/ui/label';
    import toast from 'svelte-french-toast';

    interface List {
        id: number;
        name: string;
        members: { id: number }[];
        posts_count: number;
    }

    const dispatch = createEventDispatcher();

    export let show: boolean = false;
    export let lists: List[] = [];
    export let userId: number;
    export let app: { baseUrl: string } = { baseUrl: '' };
    export let isUserBlocked: boolean = false;
    export let isFollowing: boolean = false;

    const csrfTokenMeta = document.querySelector('meta[name="csrf-token"]');
    const csrfToken = csrfTokenMeta ? csrfTokenMeta.getAttribute('content') || '' : '';

    let selectedLists: Set<number> = new Set();

    $: listMembersCount = lists.map((list) => ({
        id: list.id,
        count: list.members.length,
    }));

    $: {
        if (show) {
            selectedLists = new Set([...(isFollowing ? [lists[0]?.id] : []), ...(isUserBlocked ? [lists[1]?.id] : [])]);
        }
    }

    async function updateList(listId: number, isChecked: boolean) {
        const type = isChecked ? 'add' : 'remove';

        try {
            const data = new URLSearchParams();
            data.append('list_id', listId.toString());
            data.append('user_id', userId.toString());
            data.append('returnData', 'true');

            const requestMethod = type === 'add' ? 'POST' : 'DELETE';
            const requestUrl =
                type === 'add' ? `${app.baseUrl}/my/lists/members/save` : `${app.baseUrl}/my/lists/members/delete`;

            const response = await fetch(requestUrl, {
                method: requestMethod,
                headers: {
                    'X-CSRF-TOKEN': csrfToken,
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: data,
                credentials: 'same-origin',
            });

            const result = await response.json();

            if (response.ok) {
                const updatedList = lists.find((l) => l.id === listId);
                if (updatedList) {
                    if (isChecked) {
                        updatedList.members = [...updatedList.members, { id: userId }];
                    } else {
                        updatedList.members = updatedList.members.filter((m) => m.id !== userId);
                    }
                    lists = [...lists];
                }
            }

            if (!response.ok) {
                throw new Error(result.message || result.errors?.[0] || 'Server returned an error');
            }

            if (listId === lists[0]?.id) {
                isFollowing = isChecked;
                dispatch('followingChange', { isFollowing });
                dispatch('updateFollowerCount', { change: isChecked ? 1 : -1 });
            } else if (listId === lists[1]?.id) {
                isUserBlocked = isChecked;
                dispatch('blockStateChanged', { isUserBlocked });
            }

            toast.success(result.message || `Successfully ${type === 'add' ? 'added to' : 'removed from'} list`);
        } catch (error) {
            console.error('List update error:', error);
            toast.error(error.message || 'Failed to update list');
        }
    }
</script>

<Dialog.Root bind:open="{show}">
    <Dialog.Content>
        <Dialog.Header>
            <Dialog.Title>Add user to list</Dialog.Title>
        </Dialog.Header>

        <p class="mb-4">Choose the list you want to add the user into</p>

        <div class="space-y-4">
            {#each lists as list}
                <div class="flex items-start space-x-3">
                    <Checkbox
                        id="list-{list.id}"
                        checked="{selectedLists.has(list.id)}"
                        onCheckedChange="{(checked) => updateList(list.id, checked)}"
                    />
                    <Label for="list-{list.id}" class="space-y-1">
                        <h6 class="m-0 font-bold">{list.name}</h6>
                        <span class="text-sm text-gray-500">
                            {listMembersCount.find((l) => l.id === list.id)?.count || 0} members - {list.posts_count} posts
                        </span>
                    </Label>
                </div>
            {/each}
        </div>

        <div class="mt-4 flex justify-end">
            <Button variant="outline" on:click="{() => (show = false)}">Close</Button>
        </div>
    </Dialog.Content>
</Dialog.Root>
